<template>
  <div>
    <Nav/>
    <div class="main-top">
      <div class="bg-img">
        <img src="@/assets/images/IA/main_bg_img.png" alt="">
        <img class="bg-cover" src="@/assets/images/IA/main_bg_cover.png" alt="">
        <div class="main-title">
        </div>
        <div class="main-title">
          <h1>한국판 아동용 자기보고식 생활참여 평가</h1>
          <h2>K-COSA</h2>
        </div>
        <div class="bg-star"><img src="@/assets/images/IA/main_bg_icon.png" alt=""></div>
      </div>
    </div>
    <div class="main-box inner">
      <div class="concept">
        <h3>K-COSA<span>란?</span></h3>
        <ul>
          <li><p>K-COSA는 Child Occupational Self Assessment (COSA)의 한국판 평가도구입니다. 이 도구는 국립재활원의 연구비를 지원받아<br/>
            전주대학교 작업치료학과 정윤화 교수 연구팀에서 개정하였습니다.
          </p></li>
          <li><p>K-COSA는 치료사나 보호자의 도움을 받아 아동 스스로 ‘자신의 일상생활활동에서의 참여’를 평가하는 클라이언트 중심의 평가도구입니다.<br/>
            아동이 일상생활활동에 참여함으로써 자신의 정체성 및 수행능력과 활동의 중요성에 대해 스스로 어떻게 생각하는지를 평가합니다.
          </p></li>
          <li><p>K-COSA를 사용함으로써 아동은 자신의 일상생활을 자각할 수 있으며, 치료사와 보호자는 아동이 생각하는 활동의 가치와 활동수행수준을<br/>
            확인하여 적절한 재활 중재 목표를 세울 수 있습니다.
          </p></li>
        </ul>
      </div>
      <div class="introduce">
        <div class="introduce-box">
          <ul>
            <li>
              <div class="introduce-sub-title">
                <p>평가대상</p>
              </div>
              <div class="introduce-detail">
                <p>평가 대상자는 8-13세 장애 및 비장애 아동입니다.<br/>
                  아동이 스스로 평가하는 도구로  약 20분 정도 소요됩니다.</p>
                <div><img src="@/assets/images/IA/main_icon_1.png" alt=""></div>
              </div>
            </li>
            <li>
              <div class="introduce-sub-title">
                <p>평가항목</p>
              </div>
              <div class="introduce-detail">
                <p>아동이 가정, 학교, 지역사회에서 보편적으로 수행하는<br/>
                  자기관리, 놀이, 학습 등 다양한 활동이 포함되었습니다.<br/>
                  총 25개의 항목과 3개의 추가 질문으로 구성되었습니다.<br/>
                  항목의 이해를 돕기 위해 각 항목에는 몇 가지 예시활동을 제공합니다.
                </p>
                <div><img src="@/assets/images/IA/main_icon_2.png" alt=""></div>
              </div>
            </li>
            <li>
              <div class="introduce-sub-title">
                <p>평가척도</p>
              </div>
              <div class="introduce-detail">
                <p>일상 활동을 ‘얼마나 잘하는지(능숙도)’와<br/>
                  활동이 자신에게 ‘얼마나 중요한지(중요도)’를<br/>
                  평가합니다.
                </p>
                <div><img src="@/assets/images/IA/main_icon_3.png" alt=""></div>
              </div>
            </li>
            <li>
              <div class="introduce-sub-title">
                <p>평가장소</p>
              </div>
              <div class="introduce-detail">
                <p>평가는 주변소음이 없는 조용하고 편안한 장소에서 실시합니다.
                  치료사 또는 보호자는 평가 중 아동의 질문이나 도움 요청에 응할 수 있으며
                  평가지 작성을 위해 아동에게 충분한 시간을 제공할 수 있습니다.</p>
                <div><img src="@/assets/images/IA/main_icon_4.png" alt=""></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "@/components/Nav.vue";

export default {
  name: 'Intro',
  components: {Nav},
  data() {
    return {
      page: 0,

    }
  },

}
</script>

<style scoped>
.main-top {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  background: #0a59b7;
}

.main-top .bg-img {
  max-height: 400px;
  max-width: 1530px;
  min-width: 1140px;
  background: #4caf50;
  position: relative;
}

.main-top .bg-img img {
  width: 100%;
  height: 100%;
}

.main-top .bg-cover {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 30;
}

.main-top .main-title {
  color: #ffffff;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 40;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.main-title h1 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin-top: 10px;
}

.main-title h2 {
  font-family: 'Lexend_B', serif;
  transform: translateY(10px);
  font-size: 48px;
}

.main-top .bg-img .bg-star {
  position: absolute;
  top: 0;
  right: 50%;
  transform: translateX(50%);
  width: 1140px;
  z-index: 49;
  display: flex;
  justify-content: flex-end;

}

.main-top .bg-img .bg-star img {
  width: 289px;
  height: 243px;
}

.main-box .concept {
  width: 1000px;
  margin: 0 auto;
}
.main-box .concept ul li {
  list-style-type: disc
}

.main-box .concept h3 {
  font-family: 'Lexend_B', serif;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  margin: 55px 0 15px;
}

.main-box .concept h3 span {
  font-family: NotoSansKR,serif;
  font-weight: 700;
}

.main-box .concept p {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
}

.introduce {
  margin: 85px 0 80px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.introduce ul {
  width: 727px;
  height: 630px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.introduce ul li {
  background: #F2F2F2;
  height: 150px;
  display: flex;
  border-radius: 0 25px 25px 25px;
  overflow: hidden;
}

.introduce ul li .introduce-sub-title {
  width: 65px;
  background: #4C9AF5;
  color: #ffffff;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.introduce ul li .introduce-sub-title p {
  width: 34px;
  text-align: center;
}

.introduce ul li:nth-child(2) .introduce-sub-title,
.introduce ul li:nth-child(4) .introduce-sub-title {
  background: #B551E3;
}

.introduce ul li .introduce-detail {
  width: 662px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.introduce ul li .introduce-detail p {
  width: 412px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.001em;
}

.introduce ul li .introduce-detail div img {
  width: 100px;
  height: 100px;
}

</style>
